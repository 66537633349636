;(function () {
  const datatable = require('datatables.net-dt')

  const init = (bPaginate = true) => {
    new datatable('.js-data-table', {
      bDestroy: true,
      processing: true,
      lengthChange: false,
      ordering: true,
      language: { search: '', searchPlaceholder: 'Search...' },
      bFilter: true,
      bPaginate: bPaginate,
    })
  }

  const DataTable = { init }

  if (typeof window !== 'undefined') {
    window.DataTable = DataTable
  }

  return DataTable
})()
