import Highcharts from 'highcharts';
require("highcharts/modules/sankey")(Highcharts);
require("highcharts/modules/organization")(Highcharts);
require("highcharts/modules/exporting")(Highcharts);
Highcharts.AST.allowedAttributes.push('data-turbo-frame');
window.Highcharts = Highcharts;

(function () {
  const init = (data, nodes, width) => {
    new Highcharts.chart('container', {
      chart: {
        height: 1024,
        width: width,
        inverted: true
      },

      title: {
        text: 'Department Structure',
        align: 'left',
        x: 40,
        y: 18
      },

      series: [{
        type: 'organization',
        name: 'Department Structure',
        keys: ['from', 'to'],
        data: data,
        nodes: nodes,
        dataLabels: {
          color: '#6b7280'
        },
        colorByPoint: false,
        hangingIndent: 0,
        color: '#f1f1f1',
        borderColor: '#9a9a9a',
        nodeWidth: 100
      }],

      tooltip: {
        outside: true
      },

      exporting: {
        allowHTML: true,
        sourceWidth: 800,
        sourceHeight: 1024
      },

      navigation: {
        buttonOptions: {
          align: 'left'
        }
      },

      credits: false,

      accessibility: {
        enabled: false
      }
    });
  }

  const OrgChart = { init };

  if (typeof window !== 'undefined') {
    window.OrgChart = OrgChart;
  }

  return OrgChart;
})();
