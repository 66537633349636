import Rails from '@rails/ujs'
import '@hotwired/turbo-rails'
import * as ActiveStorage from '@rails/activestorage'

import 'channels'

import 'alpine-turbo-drive-adapter'
import Alpine from 'alpinejs'
import focus from '@alpinejs/focus'
import persist from '@alpinejs/persist'
Alpine.plugin(focus)
Alpine.plugin(persist)
window.Alpine = Alpine

Rails.start()
ActiveStorage.start()

var u = require('umbrellajs')
window.u = u

const AWS = require('aws-sdk')
window.AWS = AWS

global.toastr = require('toastr');

require.context('../images', true)

import 'chartkick/highcharts'
import '../src/application/sweetAlertConfirm'
import '../stylesheets/application/application.scss'
import 'flowbite'

require('trix')
require('@rails/actiontext')
require('inputmask/dist/inputmask')
require('../src/application/mask')
require('../src/application/placeInput')
require('../src/application/dataTable')
require('../src/application/clipBoard')
require('../src/application/timer')
require('../src/application/confirmSwalForm')
require('../src/application/orgChart')
require('../src/application/loader')
require('../src/application/slimSelect')
require('../src/application/noUiSlider')
require('../src/application/progressBar')
require('../src/application/events')
require('../src/application/localSearch')
require('../src/application/groupButtons')
require('../src/application/anySelected')
require('../src/application/completeAddress')
