document.addEventListener('DOMContentLoaded', () => {
  Alpine.start();
})

document.addEventListener('turbo:load', () => {
  SlimSelect.init();
})

document.addEventListener('turbo:before-fetch-request', e => {
  Loader.show(e);
})
