;(function () {
  const anySelected = () => {
    let selectedIds = u("input[type='checkbox']:checked").length;

    if (selectedIds > 0) {
      return true
    }

    toastr.warning('Selected at least one.');
  }

  if (typeof window !== 'undefined') {
    window.anySelected = anySelected;
  }

  return anySelected;
})();
