;(function () {
  const clipBoard = () => {
    return {
      copyText: function (field) {
        var copyText = document.getElementById(field)

        if (window.isSecureContext && navigator.clipboard) {
          navigator.clipboard.writeText(copyText.value)

          toastr.success('Link copied.')
        } else {
          toastr.error('Link not copied, check your browser permissions.')
        }
      }
    }
  }

  if (typeof window !== 'undefined') {
    window.clipBoard = clipBoard
  }
  return clipBoard
})()
