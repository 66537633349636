(function () {
  const CompleteAddress = (states) => {
    const filterCountryStates = (countryId) => {
      let filteredStates = states.filter(state => state.country_id == countryId)

      return filteredStates;
    }

    return {
      setState: function(el) {
        el.addEventListener('change', (e) => {
          let states = filterCountryStates(e.target.value);

          let availableStates = states.map(state => {
            return { 'text': state.name, 'value': state.id }
          })

          document.getElementById('address_state_id').slim.setData(availableStates);
        })
      },

      changeOutSideUS: function(el) {
        if (el.checked) {
          document.getElementById('content-address-country').classList.remove('hidden');
        } else {
          let unitedStateId = '231';
          document.getElementById('country').slim.setSelected(unitedStateId);

          document.getElementById('content-address-country').classList.add('hidden');
        }
      }
    }
  };

  if (typeof window !== 'undefined') {
    window.CompleteAddress = CompleteAddress;
  };

  return CompleteAddress;
})();
