import * as noUiSlider from 'nouislider';
import 'nouislider/dist/nouislider.css';

(function () {
  const create = (elementId, targetElement, startValue, minValue = 0, maxValue = 100) => {
    var element = document.getElementById(elementId);

    var slider = noUiSlider.create(element, {
      connect: [true, false],
      start: [startValue],
      range: {
        min: minValue,
        max: maxValue
      },
      tooltips: [
        {
          to: function ( value ) {
            return value.toFixed(1) + '%';
          }
        },
      ]
    });

    slider.on('update', function (values) {
      document.getElementById(targetElement).value = values[0];
    })
  }

  const NoUiSlider = { create }

  if (typeof window != 'undefined') {
    window.NoUiSlider = NoUiSlider
  }

  return NoUiSlider
})();
