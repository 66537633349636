;(function () {
  const groupButtons = () => {
    return {
      submit: function (path, method, title = null, confirmSwal = true) {
        let form = document.getElementById('bulk_action_form')
        form.action = path
        document.getElementById('_method').value = method

        if (confirmSwal) {
          Swal.fire({
            title: title || 'Are you sure?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes'
          }).then((result) => {
            if (result.isConfirmed) {
              form.submit()
            }
          })
        } else {
          form.submit()
        }
      }
    }
  }

  if (typeof window !== 'undefined') {
    window.groupButtons = groupButtons
  }
  return groupButtons
})()
