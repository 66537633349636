import './application'

require('../src/application/provely')

import '../stylesheets/home/custom.scss'
import '../stylesheets/home/form.scss'
import '../stylesheets/home/nav_bar.scss'
import '../stylesheets/home/social_media.scss'
import '../stylesheets/home/career_dashboard.scss'
import '../stylesheets/home/test.scss'
import '../stylesheets/home/slim_select.scss'
