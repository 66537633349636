(function () {
  const createLoader = e => {
    const loaderDiv = document.createElement('div');
    loaderDiv.classList.add('loader-div');
    const loaderContent = document.createElement('div');
    loaderContent.classList.add('loader-content');
    loaderDiv.appendChild(loaderContent);
    const id = e.target.dataset?.tsListId || e.target.id;
    document.getElementById(id).innerHTML = '';
    document.getElementById(id).appendChild(loaderDiv);
  }

  const show = e => {
    if (document.getElementById(e.target.id)) {
      turboElement = document.getElementById(e.target.id);

      turboElement.innerHTML = '';
      createLoader(e);
    }
  }

  const Loader = { show };

  if (typeof window !== 'undefined') {
    window.Loader = Loader;
  };

  return Loader;
})();
