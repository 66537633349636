import Swal from 'sweetalert2'
import Rails from '@rails/ujs'

const CustomSwal = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary',
    cancelButton: 'btn btn-secondary mr-2'
  },
  reverseButtons: true,
  buttonsStyling: false
})

window.Swal = CustomSwal

const confirmed = (element, result) => {
  if (result.value) {
    if (!!element.getAttribute('data-remote')) {
      let reloadAfterSuccess = !!element.getAttribute('data-reload')

      fetch({
        method: element.getAttribute('data-method') || 'GET',
        url: element.getAttribute('href'),
        dataType: 'json',
        success: function (result) {
          window.Swal.fire('Success!', result.message || '', 'success').then(
            _result => {
              if (reloadAfterSuccess) {
                window.location.reload()
              }
            }
          )
        },
        error: function (xhr) {
          let title = 'Error!'
          let message = 'Something went wrong. Please try again later.'

          if (xhr.responseJSON && xhr.responseJSON.message) {
            message = xhr.responseJSON.message
          }

          window.Swal.fire(title, message, 'error')
        }
      })
    } else {
      element.removeAttribute('data-confirm-swal')
      element.click()
    }
  }
}

const showConfirmationDialog = element => {
  const message = element.getAttribute('data-confirm-swal')
  const text = element.getAttribute('data-text')

  window.Swal.fire({
    title: message || 'Are you sure?',
    text: text || '',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes',
    cancelButtonText: 'Cancel'
  }).then(result => confirmed(element, result))
}

const allowAction = element => {
  if (element.getAttribute('data-confirm-swal') === null) {
    return true
  }

  showConfirmationDialog(element)
  return false
}

function handleConfirm(element) {
  if (!allowAction(this)) {
    Rails.stopEverything(element)
  }
}

Rails.delegate(document, 'a[data-confirm-swal]', 'click', handleConfirm)
Rails.delegate(document, 'button[data-confirm-swal]', 'click', handleConfirm)
