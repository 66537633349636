(function () {
  const filterPlace = place => (type, param) => {
    const component = place.address_components.filter(comp => comp.types[0] === type);

    if (!param) param = 'long_name';

    return (component && component[0] && component[0][param]) || undefined;
  };

  const placeInputApp = function (placeInputId, key, address) {
    let $dispatch;
    const placeInput = document.getElementById(placeInputId);

    const autocomplete = new google.maps.places.Autocomplete(placeInput, {
      type: ['geocode'],
    });

    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();

      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();

      const filterComponent = filterPlace(place);

      const country = filterComponent('country');
      const country_initials = filterComponent('country', 'short_name');
      const state = filterComponent('administrative_area_level_1');
      const city = filterComponent('administrative_area_level_2') || filterComponent('locality') || country || state;
      const zip_code = filterComponent('postal_code');

      $dispatch(`address-changed-${key}`, { city, country, country_initials, state, zip_code, lat, lng });
    });

    return {
      address,
      setDispatch: function (dispatch) {
        $dispatch = dispatch;
      },
      updateAddress: function (address) {
        this.address = address;
      }
    };
  };

  if (typeof window !== "undefined") {
    window.placeInputApp = placeInputApp;
  }

  return placeInputApp;
})();
