(function () {
  const confirmSwalForm = () => {
    return {
      submit: function (text) {
        let form = document.getElementById('confirm_swal_form')

        Swal.fire({
          text: text,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes'
        }).then((result) => {
          if (result.isConfirmed) {
            form.submit()
          } else {
            false
          }
        })
      }
    }
  }

  if (typeof window !== 'undefined') {
    window.confirmSwalForm = confirmSwalForm
  }
  return confirmSwalForm
})();
