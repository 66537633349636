(function () {
  const init = (records, keys) => {
    this.records = records;
    this.keys = keys;
  };

  const search = (searchText) => {
    if (!searchText) {
      return this.records;
    }

    return this.records.filter(record => {
      const found = this.keys.some(field => {
        let keys = field.split('.');
        let value = keys.reduce((a, c) => a[c], record);
        return value?.toLowerCase()?.indexOf(searchText.toLowerCase()) >= 0;
      });

      return found;
    });
  };

  const LocalSearch = { init, search };

  if (typeof window !== 'undefined') {
    window.LocalSearch = LocalSearch;
  };

  return LocalSearch;
})();
