;(function () {
  const init = (element, invervalTime) => {
    let progress = 100
    let incrementSpeed = 1
    progressInterval = setInterval(function () {
      progress -= incrementSpeed
      element.style.width = progress + '%'
      if (progress <= 0) {
        clearInterval(progressInterval)
      }
    }, invervalTime)
  }

  const ProgressBar = { init }

  if (typeof window !== 'undefined') {
    window.ProgressBar = ProgressBar
  }

  return ProgressBar
})()
