(function () {
  const timer = (finalDate) => {
    var updateTimer = setInterval(function() {
      var now = new Date().getTime()
      var timeLeft = finalDate - now

      // var days = ('0' + Math.floor(timeLeft / (1000 * 60 * 60 * 24))).slice(-2)
      var hours = ('0' + Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))).slice(-2)
      var minutes = ('0' + Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60))).slice(-2)
      var seconds = ('0' + Math.floor((timeLeft % (1000 * 60)) / 1000)).slice(-2)

      document.getElementById('timer').innerHTML = hours + ':' + minutes + ':' + seconds

      if (timeLeft < 0) {
        clearInterval(updateTimer)
        document.getElementById('timer').innerHTML = '00:00:00'
        document.getElementById('form').submit()
      }
    }, 1000);
  }

  if (typeof window !== 'undefined') {
    window.timer = timer
  }

  return timer
})();
